.menu-pager {
  display: flex;
  margin-top: 15px;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(121, 121, 121, 0.283);
  padding: 0px;
}
.menu-pager > div:nth-child(1) {
  flex: 1;
}
/* .menu-pager > div:nth-child(2) {
} */

.menu-pager > div:nth-child(1) ul {
  display: flex;
  margin: 0px;
  padding: 0px;
}
.menu-pager > div:nth-child(1) ul li {
  padding: 17px 25px;
  /* border: 1px solid red; */
  text-align: center;
  cursor: pointer;
}
.menu-pager > div:nth-child(1) ul li p {
  color: var(--td-dark);
  transition: all 1s;
  text-transform: lowercase;
}
.menu-pager > div:nth-child(1) ul li p::first-letter {
  text-transform: uppercase;
}
.menu-pager > div:nth-child(1) ul li.active {
  transition: all 1s;
  background-color: var(--td-success);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.menu-pager > div:nth-child(1) ul li.active p {
  color: var(--td-white);
  transition: all 1s;
}
