.card {
  background-color: var(--td-white);
  box-shadow: var(--td-box-shadow);
  width: 250px;
  margin-bottom: 25px;
  margin-right: 40px;
  border-radius: var(--td-border-radius);
  cursor: pointer;
  transition: all 1s;
}

.card .card-img {
}

.card .card-img figure {
  background-color: var(--td-primary);
  border-radius: var(--td-border-radius);
  padding: 20px;
}
.card .card-img figure img {
  width: 100px;
  height: 100px;
}
.card .content {
  margin-top: 10px;
}
.card .content h3 {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--td-gray);
}
.card:hover {
  transform: scale(1.1);
  transition: all 1s;
}

.card .footer-cards {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .footer-cards > * {
  border: 1px solid var(--td-cyan);
  border-radius: var(--td-border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 5px;
}
.card .footer-cards > *:hover {
  background-color: rgba(0, 0, 0, 0.066);
}
.card .footer-cards img {
  width: 20px;
  height: 20px;
}
.card .card-videos {
  background-color: var(--td-black);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--td-border-radius);
}
.card .card-videos div {
  background-color: var(--td-primary);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--td-border-radius);
}
