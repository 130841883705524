.slider-arrow {
  background-color: var(--td-primary);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}
.slider-arrow.arrow-right {
  position: absolute;
  right: 1.8rem;
  transform: translateY(-50%);
  top: 50%;
}
.slider-arrow.arrow-left {
  position: absolute;
  left: 1.8rem;
  transform: translateY(-50%);
  top: 50%;
  z-index: 1;
}
