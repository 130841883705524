.error-popup {
  background-color: var(--td-white);
  position: fixed;
  top: 10px;
  right: 10px;
  width: 300px;
  padding: 20px;
  box-shadow: var(--shadow-5);
  cursor: pointer;

  display: flex;
  align-items: center;
  z-index: 100;
}
.error-popup.failed {
  border-left: 15px solid var(--td-danger);
  color: var(--td-danger);
}
.error-popup.success {
  border-left: 15px solid var(--td-success);
  color: var(--td-success);
}
.error-popup.enter {
  animation: enter-popup-error 0.5s linear;
}
.error-popup.quit {
  transform: translateX(400px);
  transition: all 2s;
}
.error-popup span {
  font-weight: bolder;
  font-size: 1.2rem;
}
.error-popup p {
  margin-top: 8px;
  font-size: 0.9rem;
}

.error-popup > div:nth-child(1) {
  margin-right: 10px;
}
.error-popup > div:nth-child(2) {
  flex: 1;
}

@keyframes enter-popup-error {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes quit-popup-error {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(400px);
  }
}
