.pagination {
  display: flex;
  margin-top: 1rem;
}
.pagination li {
  cursor: pointer;
}
.pagination li:not(:first-child) {
  margin-left: 1rem;
}
.pagination li.selected {
  background-color: var(--td-primary);
  border-radius: 2px;
  /* display: flex; */
}
.pagination li a {
  display: flex;
  padding: 0.8rem 1.2rem;
  font-weight: bolder;
}
.pagination li.selected a {
  color: var(--td-white);
}
