button {
  padding: 15px 25px !important;
  margin: 10px 0px;
  border: 0px;
  width: 100%;
  font-family: var(--td-font-regular), "Courier New", Courier, monospace;
  cursor: pointer;
  background-color: var(--td-light);
  color: var(--td-white) !important;
  border-radius: var(--td-border-radius);
  transition: all 0.5s;
  position: relative;
}

button:hover {
  opacity: 0.8;
}

button.primary {
  background-color: var(--td-primary);
}
button.secondary {
  background-color: var(--td-secondary);
}
button.success {
  background-color: var(--td-success);
}
button.info {
  background-color: var(--td-info);
}
button.warning {
  background-color: var(--td-warning);
}
button.danger {
  background-color: var(--td-danger);
}
button.dark {
  background-color: var(--td-dark);
}

/* BUTTON EN MODE SKELETON */

/* ##################################### */
/* ##################################### */
/* ##################################### */
/* ######## BUTTON STYLE V1 ############# */
/* ##################################### */
/* ##################################### */
/* ##################################### */
.v1 button {
  border-radius: var(--td-border-radius_v1);
}
