.float-container {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: var(--td-success);
  cursor: pointer;
}
.float-container:hover {
  background-color: var(--td-teal);
}
.pannier {
  display: flex;
}
.pannier > div.first-bloc-pannier {
  flex: 2;
}
.pannier > div.second-bloc-pannier {
  flex: 1;
}
.saved {
  width: 200px;
  float: right;
}
