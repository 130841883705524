.details-historique-validation{
    /* border : 1px solid red; */
    margin-top: 1.5rem;
}
.details-historique-validation > p:nth-child(1){
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: bolder;
    color: var(--td-info);
    margin-bottom: 1rem;
}

.details-historique-validation > div {
    margin-left: 2rem;
    margin-bottom: .8rem;
}
.details-historique-validation > div p{
    line-height: 1.5rem;
    
}
.details-historique-validation > div p span {
    font-weight: bolder;
    font-size: .9rem !important;
    margin-left: 1rem;
}