.container-cards {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.empty-data {
  color: var(--td-danger);
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 50px 0px;
  width: 100%;
}
