.action-content {
}
.action-content .action-content_info_personnelle {
  display: flex;
}
.action-content .action-content_info_personnelle div {
  flex: 1;
}
.action-content .action-content_info_personnelle:nth-child(2) {
  padding-top: 1rem;
}
.action-content.cmd-validation p {
  text-align: center !important;
}
.action-content p {
  line-height: 2rem !important;
}
