.langue {
  /* border: 1px solid red; */
  display: flex;
}
.langue .langue-item {
  padding: 5px 10px;
  cursor: pointer;
  margin: 0px 5px;
  font-weight: bolder;
  border-radius: var(--td-border-radius);
}
.langue .langue-item.active {
  background-color: var(--td-primary);
  color: var(--td-white);
}
