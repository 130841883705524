.skelton {
  animation: mymove 1s linear infinite alternate;
  opacity: 0.7;
}
.sk_checkbox {
  width: 20px !important;
  height: 20px !important;
  border-radius: 2px;
}
.sk_text {
  /* width: 100%; */
  padding: 0.4rem;
}
.sk_status {
  height: 20px;
  border-radius: 4px;
}
.sk_actions {
  width: 3px;
  height: 20px;
}

.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}

@keyframes mymove {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
