@font-face {
  font-family: "policeFont";
  src: url("fonts/DMSans-Medium.ttf");
}

:root {
  --td-blue: #1d4ed8;
  --td-indigo: #4338ca;
  --td-purple: #7e22ce;
  --td-pink: #be185d;
  --td-red: #b91c1c;
  --td-orange: #b91c1c;
  --td-yellow: #a16207;
  --td-green: #15803d;
  --td-teal: #0f766e;
  --td-cyan: #0e7490;
  --td-gray: #3f3f46;
  --td-white: #ffffff;
  --td-black: #000000;
  --td-gray-dark: #0000001b;
  --td-primary: #e75300;
  --td-secondary: #ba1d25;
  --td-success: #047857;
  --td-info: #0369a1;
  --td-warning: #c2410c;
  --td-danger: #be123c;
  --td-light: #44403c;
  --td-dark: #27272a;
  --td-dark-01: #a83d42;
  --td-dark-02: #a8a8ad7b;
  --td-dark-03: #7a7a7bf3;

  --td-font-regular: "policeFont";
  --td-font-bold: "";
  --td-font-light: "";
  --td-font-italic: "";

  --td-border-radius: 5px;
  --td-border-radius_v1: 100px;
  --td-padding-main: 20px;

  --td-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-5: 10px 20px 25px 5px rgba(0, 0, 0, 0.1),
    10px 10px 10px 5px rgba(0, 0, 0, 0.04);

  /* custom for seed */
  --td-custom-bg-footer: #98979719;
}
* {
  box-sizing: border-box;
}
html {
  font-size: 14px;
  font-family: var(--td-font-regular), "Courier New", Courier, monospace;
}
body {
  background-color: var(--td-white);
  color: var(--td-light);
  box-sizing: border-box;
}

img {
  width: 100%;
  display: block;
  object-fit: cover;
}
/* header web site */
.header {
  width: 100vw;
  height: 6rem;
  background-color: var(--td-white);
  position: fixed;
  z-index: 2;
}
.header-center {
  display: flex;
  width: 95vw;
  max-width: 1170px;
  margin: 0 auto;
}
.header-center div:nth-child(1) {
  width: 20rem;
}
.header-center nav.site {
  flex: 1;
  /* border: 1px solid green; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  padding: 0 2rem;
}
nav.site ul {
  display: flex;
}
nav.site ul li {
  padding: 1rem 2rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: bolder;
  color: var(--td-dark-03);
  cursor: pointer;
  transition: all 0.5s;
}
nav.site ul li:hover,
nav.site ul li.active {
  color: var(--td-primary);
}

.header-center .user-connecter {
  flex: 2;
  display: flex;
  align-items: center;
}
.header-center .user-connecter .avatar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--td-primary);
  font-weight: bolder;
  color: var(--td-white);
  margin: 0px 10px;
  text-transform: uppercase;
}
.header-center .user-connecter div {
  flex: 1;
}
.header-center .user-connecter div:nth-child(2) {
  flex: 2 !important;
  padding: 0 20px;
  text-align: center;
}
.header-center .user-connecter div:nth-child(2) p {
  font-weight: bolder;
  text-transform: lowercase;
}
.header-center .user-connecter div:nth-child(2) span {
  text-transform: uppercase;
  font-weight: bolder;
  color: var(--td-primary);
  font-size: 0.8rem;
}
.site.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}
.site .container-main {
  flex: 1;
  padding-bottom: 20px;
}
.hero-section-center {
  width: 100vw;
  max-width: 1170px;
  height: 60vh;
  margin: 0 auto;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
}
.hero-section-item {
  position: relative;
}
.hero-section-item img {
  height: 60vh;
  object-fit: cover;
}
.hero-section-item div {
  position: absolute;
  inset: 0 0 0 0;
  /* background: linear-gradient(
    to bottom,
    rgba(34, 33, 33, 0.64),
    rgba(35, 35, 34, 0.263)
  ); */
  color: var(--td-white);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-section-center div h1 {
  margin-bottom: 1rem;
}
.hero-section-center div span {
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bolder;
  color: var(--td-primary);
}

/* categorie */
.categorie,
.articles {
  margin: 2rem 0rem;
}
.categorie-center,
.articles-center {
  width: 95vw;
  max-width: 1170px;
  margin: 0 auto;
}
.title {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin: 2rem 0;
  color: var(--td-dark-03);
}
.title span {
  color: var(--td-primary);
}
.list-categorie,
.categorie-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.categorie-item {
  width: 95%;
  margin: 0 auto;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  height: 12rem;
}
.categorie-item div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(0, 0%, 0%, 0.696);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s linear;
}
.categorie-item div span {
  color: var(--td-white);
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 1.3rem;
}
.categorie-item img {
  transition: all 0.7s linear;
  height: 12rem;
  object-fit: contain !important;
}
.categorie-item:hover > img {
  transition: all 0.7s linear;
  transform: scale(1.1);
}
.categorie-item:hover > div {
  transition: all 0.7s linear;
  background-color: hsla(0, 0%, 0%, 0.441);
}
.list-articles {
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--td-white);

  /* justify-content: space-between; */
}
.article-item {
  background-color: var(--td-white);
  width: 100%;
  box-shadow: var(--shadow-1);
  margin: 0 5px;
  margin-bottom: 1.5rem;
  border: 1px solid var(--td-dark-02);
  border-radius: 0.2rem;
  overflow: hidden;
  position: relative;
  transition: all 1s;
}
.article-item .article-image {
  padding: 2px;
  width: 100%;
  overflow: hidden;
}
.article-item .article-image img {
  object-fit: contain !important;
  height: 15rem;
}
.article-item .description-article {
  display: flex;
  flex-direction: column;
  padding: 1.4rem;
  line-height: 1.7;
  background-color: var(--td-dark-02);
  border-left: 5px solid var(--td-primary);
}

.article-item .description-article span:nth-child(1) {
  text-transform: uppercase;
  color: var(--td-primary);
  font-weight: bolder;
  font-size: 0.8rem;
}
.article-item .description-article span:nth-child(2) {
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.article-item .description-article span:nth-child(3) {
  font-weight: bolder;
  text-transform: uppercase;
}
.article-overlay {
  position: absolute;
  inset: 0 0 0 0;
  background-color: hsla(0, 0%, 0%, 0.522);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
}
.article-overlay button {
  background-color: transparent;
  width: 10rem;
  background-color: var(--td-primary);
  outline: 2px solid var(--td-white);
  outline-offset: -5px;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-size: 0.7rem;
}
.article-item:hover > .article-overlay {
  transition: all 1s;
  opacity: 1;
}

/* MODAL FORMULAIRE PASSSER COMMANDE */
.commande-form {
  display: flex;
}
.commande-form div.img {
  flex: 1;
  width: 100%;
  margin-right: 20px;
}
.commande-form div.img img {
  width: 100%;
  height: 17rem;
  object-fit: contain;
}

.commande-form .commande-data {
  /* text-align: center; */
  flex: 2;
}
.commande-form .commande-data h3 {
  color: var(--td-primary);
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bolder;
}
.commande-form .commande-data p.categorie {
  font-weight: bolder;
  font-size: 1rem;
  text-transform: uppercase;
}
.commande-form .commande-data p.categorie span {
}

.commande-form .commande-data span.description {
  color: var(--td-dark-03);
}
.commande-form .commande-data .price {
  color: var(--td-dark-03);
  font-size: 1.6rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
.commande-form .formulaire-site {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.commande-form .formulaire-site > div {
  display: flex;
  justify-content: space-between;
}
.commande-form .formulaire-site > div div {
  display: flex;
  margin-top: 10px;
  width: 100%;
}
.commande-form .formulaire-site input {
  border: 2px solid var(--td-dark-03);
  border-radius: 5px !important;
}
.popup-btn {
  width: 30%;
  /* margin: 0 auto; */
}

.footer {
  background-color: var(--td-dark-02);
  padding: 2rem 0;
}
.footer-center {
  width: 95vw;
  max-width: 1170px;
  margin: 0 auto;
}
.footer-center div.logo {
}
.footer-center div.logo figure {
  width: 200px;
}

.footer-center div.contact {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
}
.footer-center div.contact div figure {
  width: 1.6rem;
  margin-right: 10px;
}
.footer-center div.contact {
}
.footer-center div.contact .telephone {
  display: flex;
  flex-direction: column;
}
.footer-center div.contact .telephone span {
  margin-bottom: 10px;
}
.connexion-login {
  text-align: center;
}
.connexion-login .description {
  color: var(--td-dark-03);
  width: 60%;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 1.7rem;
}
.connexion-login .forgot-password span {
  color: var(--td-dark-03);
}

.connexion-login .form {
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
}

.connexion-login .form .form-input {
  display: flex;
  margin-bottom: 10px;
}
.connexion-login .form .btn-connexion {
  margin-top: 20px;
}
.connexion-login input {
  border-radius: 0px !important;
  border-bottom: 2px solid var(--td-dark-03) !important;
}
.connexion-login input::placeholder {
  padding-left: 0px !important;
}
.connexion-login .form .forgot-password {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.connexion-login .form .forgot-password a {
  text-decoration: none;
  color: var(--td-primary);
  transition: all 1s;
}
.connexion-login .form .forgot-password a:hover {
  color: var(--td-dark-02);
  transition: all 1s;
}

.connexion-login .form .forgot-password .sans-connexion {
  margin: 0 auto;
}
.connexion-login .form .forgot-password .sans-connexion a {
  color: var(--td-primary);
  font-weight: bolder;
  text-transform: capitalize;
  font-size: 1.2rem;
  transition: all 1s;
}
.connexion-login .form .forgot-password .sans-connexion a:hover {
  opacity: 0.5;
}

/* table produits */
.figure-div {
}
.figure-div img {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}
.empty {
  color: var(--td-red);
  text-transform: capitalize;
}
/* loader button */
.loader {
  position: absolute !important;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border: 16px solid #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left-color: var(--primary-dark);
  border-right-color: var(--primary-dark);
  border-radius: 100px;
  animation: loader 1s linear infinite;
}

.loader.min {
  position: relative !important;
  width: 20px !important;
  height: 20px !important;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  border: 4px solid transparent;
  border-right-color: var(--td-primary);
  border-bottom-color: var(--td-primary);
}
.loader.big {
  position: relative !important;
  width: 40px;
  height: 40px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  border: 4px solid transparent;
}
/* skeleton */
.categorie-item.skeleton {
}
.categorie-item.skeleton img {
  height: 12rem;
  animation: squelton 1s ease-in-out infinite alternate;
}
.categorie-item.skeleton span {
  width: 10rem;
  height: 1rem;
  border-radius: 5px;
  animation: squelton 1s ease-in-out infinite alternate;
}
.article-item.skeleton img {
  height: 12rem;
  animation: squelton 1s ease-in-out infinite alternate;
}
.article-item.skeleton .description-article {
  border-left: transparent;
  background-color: transparent;
}
.article-item.skeleton .description-article span {
  width: 10rem;
  height: 1rem;
  border-radius: 5px;
  animation: squelton 1s ease-in-out infinite alternate;
  margin-bottom: 5px;
}
.article-item.skeleton .description-article span:nth-child(2) {
  width: 5rem;
}
.article-item.skeleton .article-overlay button {
  animation: squelton 1s ease-in-out infinite alternate;
  height: 2.9rem !important;
}
/* end of skeleton */
/* Works on Firefox */
* {
  scrollbar-width: 0;
  scrollbar-color: transparent transparent;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--td-primary);
  border-radius: 20px;
  border: 3px solid white;
}

.empty-data {
  color: var(--td-danger);
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 50px 0px;
}
/* STATUS */
.status {
  border-radius: 5px;
  font-weight: bolder;
  color: var(--td-white) !important;
  padding: 5px 10px;
  font-size: 0.7rem;
  text-transform: uppercase;
}
.status span {
}
.status.success {
  background-color: var(--td-success);
}
.status.danger {
  background-color: var(--td-danger);
}
.status.warning {
  background-color: var(--td-warning);
}
.status.info {
  background-color: var(--td-info);
}

@keyframes squelton {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
/* 
    ##################
    ##################
    ##################
    ################## MEDIA QUERIES
    ##################
    ##################
    ##################
*/
@media screen and (min-width: 576px) {
  .article-item {
    /* width: calc(50% - 10px); */
  }
}
@media screen and (min-width: 768px) {
  .list-articles {
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--td-white); */

    /* justify-content: space-between; */
  }
  .article-item {
    /* background-color: var(--td-white); */
    width: calc(50% - 10px);
    /* box-shadow: var(--shadow-1);
    margin: 0 5px;
    margin-bottom: 1.5rem;
    border: 1px solid var(--td-dark-02);
    border-radius: 0.2rem;
    overflow: hidden;
    position: relative;
    transition: all 1s; */
  }
}
@media (min-width: 992px) {
  .article-item {
    width: calc(25% - 10px);
  }
}
/* #### IMPORT CSS COMPONENT FILES ###### */
@import url("typographie.css");
@import url("animation.css");
@import url("skeleton.css");
@import url("table.css");
