.btn-affections.show-btn {
  height: 76px;
  opacity: 1;
  visibility: visible;
  transition: all 1s;
}
.btn-affections.hidden-btn {
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: all 1s;
}

.btn-affections {
  display: flex;
  justify-content: flex-end;
}
.btn-affections div {
  width: 14rem;
}
