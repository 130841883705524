.nav-container-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100%;
  background-color: var(--td-primary);
}
.nav-container-main > div {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-container-main > div h1 {
  font-size: 1.4rem;
  font-weight: bolder;
}
nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
nav ul {
  padding: 0px;
}
nav ul.first-level {
  flex: 1;
}
nav ul.second-level {
  padding-bottom: 30px;
}
nav ul li {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

nav ul li a {
  padding: 15px 15px 15px 30px;
  text-decoration: none;
  width: 100%;
  color: var(--td-white);
  display: flex;
  justify-items: center;
  transition: all 0.5s;
}
nav ul li a span {
  margin-right: 10px;
}
nav ul li:hover {
}
nav ul li a:hover,
nav ul li a.active {
  background-color: var(--td-teal);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: all 0.5s;
}

hr {
  width: 200px;
}

/* ##################################### */
/* ##################################### */
/* ##################################### */
/* ######## NAVBAR STYLE V1 ############ */
/* ##################################### */
/* ##################################### */
/* ##################################### */

.v1 hr {
  display: none;
}

.v1 .nav-container-main > div {
  height: 120px;
  background-color: var(--td-success);
  margin-bottom: 10px;
  border-bottom: 10px solid var(--td-white);
}
.v1 nav ul li {
  width: 95%;
  margin: 0 auto;
  margin-top: 5px;
}
.v1 nav ul li a:hover,
.v1 nav ul li a.active {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-radius: var(--td-border-radius);
}
