.form {
  /* border: 1px solid green;
  display: flex;
  width: 100%; */
}
.form.row {
  display: flex;
  flex-direction: row;
}
.form.row > div {
  flex: 1;
  margin-right: 10px;
}
.form.row > div:not(:nth-child(1)) {
}
.form.column {
  display: flex;
  flex-direction: column;
}
